import React from 'react'
import classNames from 'classnames'
import RichText from '~/components/RichText/RichText'
import { SectionContainer } from '~/components/SectionContainer'
import styles from './Text.module.scss'
import { TextProps } from './Text.types'

const Text = ({
  content,
  width = 'medium',
  topPadding,
  bottomPadding,
}: TextProps) => {
  const isNarrowContent = width === 'narrow'

  return (
    <SectionContainer
      width={width}
      wrapperClassName={classNames('textSection !m-0', {
        'pt-10': topPadding,
        'pb-10': bottomPadding,
      })}
      innerClassName={isNarrowContent && '!max-w-none'}>
      <div className={styles.wrapper}>
        <div
          className={classNames(
            'textSection__content',
            styles.content,
            isNarrowContent && styles.narrowContent
          )}>
          {content?.json && <RichText content={content.json} />}
        </div>
      </div>
    </SectionContainer>
  )
}

export { Text }
